@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

body {
  font-family: var(--font-roboto);
  background-color: #212426;
}

.app {
  display: flex;
  flex-direction: column;
}

.app-container{
  padding: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }
}

.main-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
}

#empty{
 width: 50px; 
}

#title-bar{
  display: flex;
  justify-content: center;
  text-align: center;
}

h1 {
  font-size: 3rem;
  letter-spacing: 0.9px;
  background: linear-gradient(
    90deg,
    rgba(249, 211, 180, 1) 0%,
    rgba(249, 211, 180, 0.2) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

h2 {
  color: #f9d3b4;
  font-family: var(--font-raleway);
  font-size: 2rem;
  text-align: center;
}

.tic-tac-toe-container {
  display: flex;
  justify-content: center;
}

.tic-tac-toe{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  
  color: #f9d3b4;
  font-family: var(--font-raleway);
}

.square{
  width: 60px;
  height: 60px;
  border: solid 3px black;
  background-color: #282b2e;
  
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  cursor: pointer;
}

.scoreboard{
  color: #f9d3b4;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scoreboard div:first-of-type(1){
  display:flex;
  justify-content:center;
  align-items: center;
}

.scoreboard-container{
  display:flex;
  justify-content:space-around;
  gap: 30px;
  flex-direction: row;
  align-items: center;
}

.scoreboard p {
  text-align: center;
}

#playerselect{
  display: flex;
  align-items: center;
}

.playerbutton{
  padding: 20px;
  margin: 10px;
  background-color: #282b2e;
  border: solid 2px black;
  cursor: pointer;
  color: #f9d3b4;
  box-shadow: 5px 5px 7px #131314, -5px -5px 7px #222527
}

#player-title{
  color: #f9d3b4;
  font-family: var(--font-raleway);
  font-size: 1.25rem;
  letter-spacing: 1px;
}

.current-player{
  color: #f9d3b4;
  font-family: var(--font-raleway);
  font-size: 2rem;
  letter-spacing: 2px;
}

#reset-button{
  color: #f9d3b4;
  font-family: var(--font-raleway);
  font-size: 2rem;
  letter-spacing: 2px;
  background-color: #282b2e;
  cursor: pointer;
  border: solid 2px black;
  box-shadow: 5px 5px 7px #131314, -5px -5px 7px #222527
}